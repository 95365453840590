@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    height: 100vh;
    width: 100vw;
    background-color: #292524;
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;
  }
}
